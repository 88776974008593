﻿.navbar svg,
.navbar i.fas,
.navbar span.fa-layers-counter,
section#sec_salebanner i.fas,
section#sec_salebanner svg,
.btn i.fa-solid {
    visibility: hidden;
}

html.fontawesome-i2svg-complete .navbar svg,
html.fontawesome-i2svg-complete .navbar i.fa-solid,
html.fontawesome-i2svg-complete .navbar span.fa-layers-counter,
html.fontawesome-i2svg-complete section#sec_salebanner i.fa-solid,
html.fontawesome-i2svg-complete section#sec_salebanner svg,
html.fontawesome-i2svg-complete .btn i.fa-solid {
    visibility: visible !important;
}

.nav-link.active {
    text-decoration: underline;
}

#nav_help {
    font-size: 12px;
    height: 30px;

    nav div.dropdown-menu {
        z-index: 1030;
    }

    & > a, & > span > a, & > ul > li > a, & > span > a:hover, & > ul > li > a:hover, & > a:hover, & > .btn, & > nav > a, & > nav > div > a {
        color: #FFF;
        text-decoration: none;
        font-size: 12px;
    }
}

/* NAVS */
/* adds some margin below the link sets  */
nav#nav_main {
    .dropdown-menu div[class*="col"] {
        margin-bottom: 1rem;
    }

    .dropdown-menu {
        border: none;
    }

    a.dropdown-toggle[aria-expanded="true"] {
        color: #1cb0f6;
    }
}

/* nav logo */
a.navbar-brand img {
    width: 200px;
    height: 70px;
    margin: 0 auto;
}

.breadcrumb {
    font-size: .8rem;
}

@media screen and (max-width: 400px) {
    a.navbar-brand img {
        height: 42px;
        width: 120px;
    }

    nav#nav_main .dropdown-menu {
        top: 52px;
    }

    a#cartlink {
        font-size: .75rem;
    }
}

@media screen and (min-width: map-get($grid-breakpoints, lg)) {
    nav#nav_main {
        /* remove the padding from the nav-item and add some margin to give some breathing room on hovers */
        .nav-item {
            padding: .5rem .5rem;
            margin: 0 .25rem;
        }
        /* makes the dropdown full width  */
        .dropdown-full {
            position: static;

            .dropdown-menu {
                width: 100%;
                left: 0;
                right: 0;
                /*  height of nav-item  */
                top: 75px;
                border: 0;
                border-radius: 0;
                background-color: #fff;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
            }
        }
    }
}

.aa-dropdown-menu {
    @extend .dropdown-menu;

    &:not(.aa-empty) {
        @extend .border;
    }
}

.aa-empty {
    background: none;
}

.aa-suggestion {
    white-space: normal;
    overflow: hidden;
    padding: 0;
    @extend .dropdown-item;
    @extend .border;
    @extend .border-white;
    @extend .border-left-0;
    @extend .border-right-0;
}

.aa-suggestion.aa-cursor {
    @extend .dropdown-item, .active;
    @extend .border-top;
    @extend .border-bottom;
}

.aa-open {
    @extend .show;
}

.algolia-autocomplete {
    width: 100%;
}

/* set sizing for checkout simple nav */
nav#nav_checkout {
    a.navbar-brand img {
        height: 42px;
        width: 120px;
    }

    .dropdown-full .dropdown-menu {
        top: 52px;
    }

    a#cartlink {
        font-size: .75rem;
    }

    .nav-link {
        line-height: .85rem;

        svg {
            font-size: 1.3333333333em;
            line-height: 0.75em;
            width: 0.875em;
            height: 1em;
        }

        svg.cart {
            width: 1.125em;
        }

        svg.search {
            width: 1em;
            transform: scale(-1,1);
        }
    }
}
