﻿//Bootstrap variables
//$blue: #009fea;
$blue: #0071dc;
$darkerblue: #0058ac;
$red: #d33131;
//$orange: #FA811B;
$orange: #db4c12;
$yellow: #ffb020;
$green: #4C7D08;
$black: rgba(0,0,0,1.0);
//$cart-primary: lighten($green, 10%);
$preorder: #294700;
$preorder-light: #7fda00;
$cart-primary: $blue;
$addon: #7d156f ;
$gray-100: #f8f9fa;
$light: $gray-100;
$yiq-contrasted-threshold: 155;
$white: #fff;
$secondary: #616970;
$secondarydk: #5e6972;
$danger: $red;
$spacer: 1rem;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.15;
$headings-margin-bottom: ($spacer / 3);
$btn-border-radius: .2rem;
$btn-border-radius-lg: .2rem;
$btn-border-radius-sm: .2rem;
$jumbotron-padding: 2rem;
$jumbotron-bg: $white;
$tooltip-border-radius: .2rem;
$breadcrumb-item-padding: 0.4rem;
$breadcrumb-margin-bottom: 0rem;
$breadcrumb-bg: $white;
$line-height-base: 1.75;
$line-height-sm: 1.75;
$line-height-lg: 1.75;
$zindex-dropdown: 1035;
$display5-size: 1.5rem;
$display5-weight: 300;
$breadcrumb-active-color: #565a5f;
$med: #f4f5f6;


$theme-colors: ( 
    "primary": $secondary, 
    "secondary": $blue,
    "secondary-dark": $darkerblue,
    "med": $med,
    "med-dark": #bdc1c4,
    "cart-primary": $cart-primary,    
    "addon": $addon,
    "closeout": $red,
    "instbonus": #d33131,
    "hotdeal": #e65926,
    "preorder": $preorder,
    "preorder-light": $preorder-light,
    "prodpromo": $addon,
    "vip": $addon,
    "coupon": $orange
);

//Other variables
$font-size-xs: $font-size-base * .8125;
$stars: $darkerblue;
$navbar-light-color: rgba($black, .6) !default;
$navbar-light-hover-color: rgba($black, .8) !default;