﻿.thumbwrap {
    margin: 0;
    width: 100%;
    text-align: center;
    overflow: hidden;
    z-index: 200;

    .altimages {
        width: 100%;
        float: left;
        clear: both;
        margin: 0 0 10px 0;
        z-index: 200;

        .altimgbox {
            float: left;
            width: 42px;
            height: 42px;
            padding: 0;
            text-align: center;
            cursor: pointer;
            overflow: hidden;
            margin: 5px 10px 5px 0;
            border: 1px solid #FFF;
            background: #FFF;
            z-index: 200;

            img {
                margin: 0 auto;
                z-index: 200;
            }
        }

        .hilite {
            border: 1px solid $primary;
            z-index: 200;
        }

        .hilite:hover {
            border: 1px solid $primary;
        }

        .endthumbrow {
            margin-right: 0;
            z-index: 200;
        }
    }
}

.image_gallery_container {
    overflow: hidden;
    z-index: 0;
}

.imgbox {
    margin: 0 auto;
    width: 260px;
    text-align: center;
    overflow: hidden;
    margin-bottom: 15px;
    z-index: 200;

    div.image_gallery_container {
        margin-top: 5px;
    }
}

.nobotmargin {
    margin-bottom: 0;
}

.addtopmargin {
    margin-top: 10px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    margin-bottom: 10px;
    z-index: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;
    }
}

.text-normal {
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.75;
}
