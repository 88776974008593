﻿.card-drilling-specs {
    min-width: 540px;

    .specs-container {
        height: 538px;
        width: 538px;

        #ball-image {
            height: 538px;
            width: 538px;
        }

        div {
            position: absolute;
        }

        #left-top-container {
            left: 140px;
            top: 15px;
        }

        #right-top-container {
            left: 338px;
            top: 15px;
        }

        #left-hole-container {
            left: 140px;
            top: 125px;
        }

        #right-hole-container {
            left: 338px;
            top: 125px;
        }

        #bridge-container {
            left: 239px;
            top: 135px;
        }

        #left-reverse-container {
            left: 50px;
            top: 175px;
        }

        #left-forward-container {
            left: 50px;
            top: 95px;
        }

        #right-reverse-container {
            left: 428px;
            top: 175px;
        }

        #right-forward-container {
            left: 428px;
            top: 95px;
        }

        #left-true-container {
            left: 10px;
            top: 255px;
        }

        #right-true-container {
            left: 468px;
            top: 255px;
        }

        #left-cut-container {
            left: 190px;
            top: 225px;
        }

        #right-cut-container {
            left: 290px;
            top: 225px;
        }

        #thumb-hole-container {
            left: 239px;
            top: 325px;
        }

        #thumb-forward-container {
            left: 329px;
            top: 285px;
        }

        #thumb-reverse-container {
            left: 329px;
            top: 365px;
        }

        #left-bottom-container {
            left: 140px;
            top: 445px;
        }

        #right-bottom-container {
            left: 338px;
            top: 445px;
        }

        .form-control {
            padding: 0;
            width: 60px;
            height: 30px;
            text-align: center;
        }

        label {
            margin-bottom: 0;
            background-color: #FFF;
        }
    }
}
