/* simple additions for fixed percent sizes adding to bootstrap base of w-25, w-50, w-75, w-100 */
.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
    .w-sm-15 {
        width: 15% !important;
    }

    .w-sm-20 {
        width: 20% !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }
}

/* Special colors and styles for buttons - only use if really necessary */
/* handles pagination - changes color from primary to secondary */
.page-link {
    color: $dark !important;
}

.page-item.active .page-link {   
    border-bottom: 2px solid $dark !important;    
    color: $dark !important;
    font-weight: 700;
    border-radius: 0;
/*    background-color: #fff !important;
    border: 1px solid $dark !important;
    color: $white !important;
    border-radius: $border-radius;
    -webkit-box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
    color: $dark !important;*/
}

.btn-shadow {
    -webkit-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 7px 3px rgba(0,0,0,0.15);
}


/* Special colors and styles for backgrounds - only use if really necessary */
.bg-primarydk {
    background: $secondarydk;
}

.seethru-bar-cart-dark {
    color: white;
    background: #343a40; /* Old browsers */
    background: rgba(0,0,0,0.7);
    border-top: 1px solid rgba(0,0,0,0.8);
}

/* Special Styles for form elements */

/* add pointer cursor to labels for custom radio button lists */
label.custom-control-label {
    @extend .px-2;
    cursor: pointer;
}

label.custom-control-label:hover {
    @extend .bg-light;
    @extend .rounded;
    @extend .text-dark;
}

/* custom checkboxes don't show up well on certain colors so we use the alert text color to border the label:before (which is used to style the input in bootstrap custom form elements) */
@each $color, $value in $theme-colors {
    .alert-#{$color} label.custom-control-label:before {
        border: 1px solid theme-color-level($color, $alert-color-level);
    }
}

@include media-breakpoint-up(sm) {
    /* override bootstrap input focus to border entire input group with class input-group-shadow */
    .input-group.input-group-shadow {
        @if $enable-rounded {
            // Manually use the if/else instead of the mixin to account for iOS override
            border-radius: $input-border-radius;
        }
        @else {
            // Otherwise undo the iOS default
            border-radius: 0;
        }

        &:focus-within {
            color: $input-focus-color;
            background-color: $input-focus-bg;
            border-color: $input-focus-border-color;
            outline: 0;
            // Avoid using mixin so we can pass custom focus shadow properly
            @if $enable-shadows {
                box-shadow: $input-box-shadow, $input-focus-box-shadow;
            }
            @else {
                box-shadow: $input-focus-box-shadow;
            }
        }

        input:focus {
            box-shadow: none;
        }

        button:focus {
            box-shadow: none;
        }
    }
}

/* Special colors and styles for drop down menus - only use if really necessary */
/* allows me to use radiobuttonlist elements in drop down menus for drill down filtering results */
.dropdown-menu {
    input {
        display: none !important;
    }

    label {
        padding: 0 1.25rem;
        text-wrap: none;
        white-space: nowrap;
        cursor: pointer;
        display: block;
        width: 100%;

        &:hover {
            background: $light !important;
        }
    }
}

/*widebox setting to replace container*/
.widebox {
    max-width: 1440px;
    margin: auto;
    width: 100%;
    padding-left: 1.5rem;
}

/* The side navigation menu */
.sidenav {
    height: 100%;
    width: 0; /* change this with JavaScript */
    position: fixed;
    z-index: 1700;
    top: 0;
    left: 0;
    background-color: #f8f9fa;
    overflow-x: hidden;
    padding-top: 50px;
    transition: 0.3s;
    box-shadow: 6px 0px 16px 0px rgba(0,0,0,0.3);
}

.sidenav a.dropdown-item, .sidenav h5 a {
    padding: 0 1.5rem;
    text-decoration: none;
    font-size: 18px;
    color: #343A40;
    display: block;
    transition: 0.2s;
    text-align: left;
}

.sidenav a.dropdown-item:hover, .sidenav h5 a:hover {
    color: #000;
}

.sidenav card a {
    font-size: 16px;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 36px;
    margin-right: 50px;
}

.sidenav.openNav {
    width: 100%;
}

/* checkout nav buttons need special font-sizing based on breakpoints */

.nav-btn-group {
    font-size: .75rem;
}

@media screen and (min-width: map-get($grid-breakpoints, md)) {
    .nav-btn-group {
        font-size: 1.2rem;
    }

    .sidenav.openNav {
        width: 50%;
    }
}

.navOpenNoScroll {
    overflow: hidden;
    position: fixed;
}

.nav-item {
    cursor: pointer;
}

.tagsright {
    position: absolute;
    right: 1.1875rem;
    top: 1.1875rem;
    z-index: 1000;
    font-size: $font-size-sm;
    cursor: pointer;
}

.tagsright:hover + div.prod-card{
    box-shadow: 0 2px 15px rgba(0,0,0,.2) !important;
    cursor: pointer;
}

a.prlnk {
    display: flex;
    flex-direction: column;
    color: #000;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
}

.trendprod {
    padding: 0.625rem 0.625rem 0.625rem 3.125rem;
    border: $border-width solid transparent;
    font-size: $font-size-sm;
    line-height: 100%;
    background-color: #fff;

    span {
        color: $danger;
    }

    span.retail {
        text-decoration: line-through;
        color: $secondary;
    }

    &:hover {
        text-decoration: none;
        border: $border-width solid $light;
    }
}

.pglinkbuttonwithproduct {
    padding: 1rem 0.5rem 1rem 3rem;
    border: $border-width solid transparent;
    font-size: $font-size-sm;
    line-height: 100%;
    background-color: #fff;

    span {
        color: $danger;
    }

    &:hover {
        text-decoration: none;
        border: $border-width solid $light;
    }
}

.trendbox {
    margin-bottom: 0.3125rem;
    padding: 0.5rem 0.3125rem;
}

.trendboxlinkout {
    padding: 0.625rem 0.25rem 0.1875rem 0.9375rem;
    border-top: $border-width solid $light;
    margin-top: 0.25rem;
}



@media screen and (min-width: 576px) {
    div.prod-card {
        min-height: 289px;
    }
}

/* prodbox square */

div.prod-square {
    /*overflow: hidden;*/
    font-size: $font-size-sm;
    cursor: pointer;

    .normal-price {
        color: lighten($dark, 30%);
        text-decoration: line-through;
        font-size: 0.9375rem;
        line-height: 0.875rem;
    }

    span.stars {
        font-size: 0.75rem;
        color: $stars;

        svg.icon {
            width: 1.125em;
            margin: 0 0.125em;
        }
    }

    em {
        color: $danger;
        font-style: normal;
        font-weight: $font-weight-bold;
    }

    span.retail {
        color: $secondary;
        text-decoration: line-through;
        font-size: 0.9375rem;
        line-height: 0.875rem;

        em {
            color: $secondary;
        }
    }

    span.everyday {
        color: $dark;
        text-decoration: line-through;
        font-size: 0.9375rem;
        font-weight: 500;
        line-height: 0.875rem;

        em {
            color: $danger;
        }
    }

    span.yourprice {
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        line-height: 0.875rem;
    }

    div.prod-img-box {
        width: 100%;
        height: 0;
        padding-top: 50%;
        padding-bottom: 50%;
        overflow: hidden;

        img {
            max-width: 80%;
            max-height: 200px;
            object-fit: contain;
        }
    }

    &:hover {
        cursor: pointer;
    }

    &:hover div.prod-img-box {
        box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
    }

    .pricebox.prod-card-ebi {
        background-color: $red !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        padding-bottom: 10px !important;
    }



    .prod-img-box.prod-card-ebi {
        border: 1px solid $red !important;
    }

    .pricebox.prod-card-storm {
        background-color: $blue !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        padding-bottom: 10px !important;
    }

    .prod-img-box.prod-card-storm {
        border: 1px solid $blue !important;
    }

    .pricebox.prod-card-rotogrip {
        background-color: $red !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        padding-bottom: 10px !important;
    }

    .prod-img-box.prod-card-rotogrip {
        border: 1px solid $red !important;
    }

    .pricebox.prod-card-900global {
        background-color: #fab31b !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        padding-bottom: 10px !important;
    }

    .prod-img-box.prod-card-900global {
        border: 1px solid #fab31b !important;
    }

    .noloadoverlay {
        width: 0 !important;
        height: 0 !important;
        visibility: hidden !important;
    }

    .loadoverlay {
        visibility: visible;
        font-weight: 500;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 1 !important;
        border-top-left-radius: .5rem !important;
        border-top-right-radius: .5rem !important;
    }

    li {
        line-height: 1.25rem !important;
        font-weight: 500;
    }
}

div.bg-light a div.prod-square div.prod-img-box img, div.bg-med a div.prod-square div.prod-img-box img, div.bg-white a div.prod-square div.prod-img-box img {
    mix-blend-mode: darken !important;
}
div.pboxdiv.bg-white {
    box-shadow: inset 0 0 60px #eee !important;
}

/* Ensure equal height within a row */
.rowsameheight {
    display: flex;
    flex-wrap: wrap;
}

.rowsameheight > * > * {
    flex: 1;
    min-height: 100%; /* Adjust as needed */
}

.rowsameheight div.rounded {
    border-radius: .5rem !important;
}

div.prod-square div.prod-img-box, div.prod-square:hover div.prod-img-box {
    box-shadow: none !important;
}

div.pboxdiv:hover, div.divprodad:hover {
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15) !important;
    cursor: pointer !important;
    text-decoration: none !important;
}

@media screen and (max-width: 767px) {
    .display-3, .display-4 {
        line-height: 2.25rem !important;
    }
}

@media screen and (min-width: 767px) {
    .display-3, .display-4 {
        line-height: 3rem !important;
    }
}
/* add display 5 to have a slightly smaller display font size for ads */
.display-promo {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 2.25rem;
}

div#collapseFilters {
    .noloadoverlay {
        width: 0 !important;
        height: 0 !important;
        visibility: hidden !important;
    }

    .loadoverlay {
        visibility: visible;
        font-weight: 500;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(248, 249, 250, 0.8);
    }
}

div#linkwrapper, div#sec_lastchance, div#checkoutbox {
    .noloadoverlay {
        width: 0 !important;
        height: 0 !important;
        visibility: hidden !important;
    }

    .loadoverlay {
        visibility: visible;
        font-weight: 500;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 9999;
    }
}

div#PromoBox {
    .noloadoverlay {
        width: 0 !important;
        height: 0 !important;
        visibility: hidden !important;
    }

    .loadoverlay {
        visibility: visible;
        font-weight: 500;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 9999;
    }
}

a.promolink {
    .noloadoverlay {
        width: 0 !important;
        height: 0 !important;
        visibility: hidden !important;
    }

    .loadoverlay {
        visibility: visible;
        font-weight: 500;
        line-height: 1rem;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        z-index: 9999;
    }
}

/* lightbox */
/* overlay */
#overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,159,234,0.7); /* Blue background with opacity */
    z-index: 2000; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

#overlaytext {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3.125rem;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    line-height: 70%;

    span.h4 {
        letter-spacing: .05em;
        line-height: 90%;
    }
}
/* video display */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    margin-bottom: 0.625rem;
    z-index: 0;
}

#vidplayer embed, #vidplayer iframe, #vidplayer object, .video-container embed, .video-container iframe, .video-container object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}

.prodpgpromobox {
    background-repeat: no-repeat;
    background-position: 10px center;
    background-size: 80px 80px;

    p.lb {
        line-height: 110%;
        padding: 0 0 0 100px;
    }

    &-instbonus {
        background-image: url(/images/badges/instbonus-badge-red.png);
    }

    &-closeout {
        background-image: url(/images/badges/closeout-clearbg.png);
    }

    &-hotdeal {
        background-image: url(/images/badges/hot-deal-clearbg.png);
    }

    &-addon {
        background-image: url(/images/badges/addon-badge-purple.png);
    }

    &-prodpromo {
        background-image: url(/images/badges/exclusive-deal-clearbg.png);
    }
}

.text-stars {
    color: $stars;

    &:hover {
        text-decoration: none;
    }
}

.braintree-hosted-fields-invalid {
    @extend .is-invalid;
}

.braintree-hosted-fields-valid {
    @extend .is-valid;
}

.invalid-form-input {
    background-color: theme-color-level("danger", $alert-bg-level);
    color: theme-color("danger");
}

/* use div instead of control to display radio button list invalid feedback */

div.radio-button-box.is-invalid ~ .invalid-feedback {
    display: block;
}

div.radio-button-box.is-invalid label {
    color: $danger;
}

.checkout-card {
    width: 340px;
}

.checkout-card > .card-header > .card-title {
    font-weight: 700;
    font-size: 1.05rem;
}

.checkout-card > .card-body > .checkout-input-group {
    overflow: auto;
}
//Extend bootstrap display sizes because we need a smaller one.
.display-5 {
    font-size: $display5-size;
    font-weight: $display5-weight;
    line-height: $display-line-height;
}

.checkout-form {
    label {
        font-weight: 700;
        font-size: 0.75rem;
        font-style: italic;
        color: #444;
    }

    .form-control {
        padding: 0;
        height: 1.75rem;
        background: #fff;
        font-weight: 700;
        text-indent: .25rem;
        border-radius: 0;
        line-height: 1.75rem;
        font-size: 0.85rem;
    }

    select.form-control {
        height: 1.75rem !important;
    }
}


section.main {
    padding-top: 30px;
    padding-bottom: 30px;

    &:first-of-type {
        border-top: none;
    }

    .row {
        margin-bottom: 20px;
    }
}

.card {
    margin-bottom: 15px;
    margin-top: 15px;
}

.dropdown-menu {
    z-index: 1500;
}

.text-shadow {
    text-shadow: 0 1px 2px rgba(0,0,0,.5) !important;
}

.obj-shadow {
    box-shadow: 0 2px 4px rgba(0,0,0,.5) !important;
}

.obj-shadow:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,.8) !important;
}

.text-uline {
    text-decoration: underline !important;
}

ul.rotator, ul.rotator > li {
    list-style: none;
    margin-before: 0;
    -webkit-margin-before: 0;
    padding-start: 0;
    -webkit-padding-start: 0
}
/*product boxes */
/* Product Summary Boxes */
.tooltip-inner {
    line-height: 130% !important;
    padding-bottom: 8px !important;
}
/* COLORS */
/*.bg-med {
    background-color: $gray-200 !important;
}*/

/* animations */
/*handles shopper approved rotation on home page*/
.rotator {
    list-style: none;
    text-align: left;
    padding: 0;
    width: 100%;
    position: relative;
    display: block;
    float: left;
    margin-bottom: 40px;

    > li {
        opacity: 0;
        margin: 0 auto;
        width: 100%;
        position: absolute;
        left: 0;
        top: 10px;
        font-size: 12px;
        color: #6c757d;
        line-height: 160%;
    }

    > li i {
        font-size: 18px;
        color: #343a40;
    }

    > li:first-child {
        animation: loop 20s infinite;
    }

    > li:nth-child(2) {
        animation: opsecond 20s infinite;
    }

    > li:last-child {
        animation: oplast 20s infinite;
    }
}

@keyframes loop {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    44% {
        opacity: 0;
    }
}

@keyframes opsecond {
    33% {
        opacity: 0;
    }

    44% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    77% {
        opacity: 0;
    }
}

@keyframes oplast {
    66% {
        opacity: 0;
    }

    77% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 1;
    }

    10% {
        opacity: 0;
    }
}
/* handles home hero ball images rotation */
.imgrotator {
    position: relative;
    display: block;
    float: left;

    > div {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
    }

    > div > a > img {
        margin: 0 auto;
    }

    > div:first-child {
        opacity: 1 !important;
    }

    > div:nth-child(2) {
        animation: img_three_first 18s infinite;
    }

    > div:nth-child(3) {
        animation: img_three_second 18s infinite;
    }

    > div:nth-child(4) {
        animation: img_three_third 18s infinite;
    }
}

@keyframes img_three_first {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    33% {
        opacity: 1;
    }

    44% {
        opacity: 0;
    }
}

@keyframes img_three_second {
    33% {
        opacity: 0;
    }

    44% {
        opacity: 1;
    }

    66% {
        opacity: 1;
    }

    77% {
        opacity: 0;
    }
}

@keyframes img_three_third {
    66% {
        opacity: 0;
    }

    77% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }

    0% {
        opacity: 1;
    }

    10% {
        opacity: 0;
    }
}



@keyframes imgfirst {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes imgsecond {
    45% {
        opacity: 0;
    }

    55% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* handles fixed height for home hero to avoid CLS wore web vitals issue with google */

.hh-height {
    @include media-breakpoint-only(xs) {
        max-height: 172px;
        height: 100%;
    }

    @include media-breakpoint-only(sm) {
        height: 322px;
    }

    @include media-breakpoint-only(md) {
        height: 352px;
    }

    @include media-breakpoint-only(lg) {
        height: 146px;
    }

    @include media-breakpoint-only(xl) {
        height: 176px;
    }
}


.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
}

.cartbox_item_namespan {
    line-height: 1.2rem;
}

.cartqtybox {
    width: 70px;
}

.addressboxinfo {
    border-bottom: 1px solid #CCC;
    padding-bottom: 7px;
}

@media screen and (max-width: 480px) {
    .addressboxinfo {
        border-bottom: 0;
        padding-bottom: 0;
    }

    .cartbox_head {
        margin-top: 10px;
    }
}

.paypal-div {
    height: 48px;
}

.fixed-width-box {
    width: 300px;
}

.fixed-width-button {
    width: 300px;
    height: 50px;
}

@media screen and (max-width: 766px) {
    .fixed-width-box {
        width: 100%;
    }

    .fixed-width-button {
        width: 100%;
    }
}

.paypal-box {
    width: 190px;
    height: 50px;
    padding-top: 11px;
}

.discountItemError {
    background-color: #fcc;
    border: 3px solid #f00;
}

.bg-dkblue {
    background-color: #002d3f;
}

@media screen and (min-width: map-get($grid-breakpoints, sm)) {
    /* customizable snowflake styling */
    .snow {
        background-image: url(/images/backgrounds/snow-1.png), url(/images/backgrounds/snow-2.png), url(/images/backgrounds/snow-main-new.png);
        animation: snow 30s linear infinite;
    }

    @keyframes snow {
        0% {
            background-position: 0px 0px, 0px 0px, 0px 0px;
        }

        100% {
            background-position: 500px 1000px, 400px 400px, 300px 300px
        }
    }
}

.input-group-sm .input-group-text.input-group-text-fixedheight {
    height: $input-height-sm;
}

.input-group-sm > .input-group-prepend > .input-group-text.input-group-text-twoline {
    line-height: 1;
    font-weight: $font-weight-normal;
}

.d-when-open {
    display: none;
}

.d-when-closed {
    display: block;
}

body.js-business-open .d-when-open {
    display: block;
}

body.js-business-open .d-when-open-chat {
    display: block;
    position: fixed;
    bottom: 30px;
    left: 20px;
    top: auto;
    right: auto;
}

body.js-business-open .d-when-closed {
    display: none;
}

.card-columns {
    @include media-breakpoint-up(sm) {
        column-count: 1;
    }

    @include media-breakpoint-up(md) {
        column-count: 3;
    }
}

span.countdown-period::before {
    content: " ";
}

span.countdown-period::after {
    content: " ";
}

#sec_mobnav {
    .nav-link {
        line-height: .85rem;

        svg {
            font-size: 1.3333333333em;
            line-height: 0.75em;
            width: 0.875em;
            height: 1em;
        }

        svg.cart {
            width: 1.125em;
        }

        svg.search {
            width: 1em;
            transform: scale(-1,1);
        }
    }
}

.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.125em;
}

.img-40x3 {
    position: relative;

    img:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (3 / 40) * 100%;
    }

    > img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.img-16x3 {
    position: relative;

    img:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (3 / 16) * 100%;
    }

    > img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

#chatTriggerContainer {
    display: block;
    position: fixed;
    bottom: 10px;
    left: 10px;
    top: auto;
    right: auto;
    z-index: 1000001;
}

#chatTrigger {
    cursor: pointer;
    width: 45px;
    height: 45px;

    svg {
        width: 45px;
        height: 45px;
    }

    i {
        width: 45px;
        height: 45px;
    }

    span {
        font-weight: 900;
    }
}

@media screen and (min-width: 1024px) {
    #chatTriggerContainer {
        bottom: 20px;
        left: 20px;
    }

    #chatTrigger {
        width: 65px;
        height: 65px;

        svg {
            width: 65px;
            height: 65px;
        }

        i {
            width: 65px;
            height: 65px;
        }

        span {
            font-size: 21px;
        }
    }
}

div.cart-img-box {
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

body .acsb-trigger {
    z-index: 9999;
}

.summarytags {
    font-size: .8125rem;

    small:not(:last-child)::after {
        content: ' / ';
    }
}

.shippingoptions {
    .shipspeed {
        font-weight: bold;
    }

    .custom-control-label::before {
        border-color: var(--gray-dark);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #0071dc !important;
        background-color: #0071dc !important;
    }

    input[type="radio"]:checked + label.custom-control-label {
        font-weight: bold;
        color: #0071dc !important;
        text-transform: uppercase;
    }

    .custom-control-input.is-valid ~ .custom-control-label {
        color: inherit;
    }

    .custom-control-input.is-valid ~ .custom-control-label::before {
        border-color: var(--gray-dark);
    }
}

.vip-celebrate svg {
    width: 2rem;
    height: 2rem;
}

button.btn-raw {
    all: unset;
    cursor: pointer;
}

button.btn-raw:focus {
    outline: 1px solid #000;
}

.entryCouponHeader {
  background:url("/images/backgrounds/orange-ribbon-bg.png") no-repeat;
}

.entryCouponHeader.holidayCouponHeader {
  background:url("/images/backgrounds/red-ribbon-bg.png") no-repeat;
}

.otherstyle {
    width: 50px;
    height: 50px;
}

.object-fit-contain {
    object-fit:contain;
}

.filtersection .fa-angle-up {
  transition: all 0.3s ease;
}
.filtersection.collapsed .fa-angle-up {
  transform: rotate(180deg);
}