﻿.ekko-lightbox-container {
    overflow: auto;
}

body.ekko-lightbox-primary div.modal-content {
    background: $primary !important;
    color: $white !important;
}

body.ekko-lightbox-secondary div.modal-content {
    background: $secondary !important;
    color: $white !important;
}

body.ekko-lightbox-danger div.modal-content {
    background: $danger !important;
    color: $light !important;
}

body.ekko-lightbox-dark div.modal-content {
    background: $dark !important;
    color: $light !important;
}

body.ekko-lightbox-light div.modal-content {
    background: $light !important;
    color: $dark !important;
}

//Fixes 5.3.0 with IE 11. Should be removable in 5.4.0
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    .ekko-lightbox .modal-dialog {
        flex: 100% !important;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
        -ms-overflow-style: none;
    }
}